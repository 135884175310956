<template>
  <div style="position: relative!important;">
    <div class="loader-wrap" v-if="loading==true">
      <div class="loader"></div>
    </div>
    <div v-else-if="loading==false">
      <div class="header-additional">
        <div class="container">
          <div class="header-additional-block">
            <div class="header-additional__logo">
              <img src="images/1000-bala.png" width="70" height="30">
              <div class="header-additional__desc">
                {{ $t('mynBala.заголовок') }}
              </div>
            </div>
            <div style="display: flex;width: 70%; justify-content: flex-end;">
              <el-select
                  style="margin-right: 1rem"
                  @change="changeLang()"
                  :placeholder="$t('Qabilet.ВыберитеЯзык')"
                  v-model="locale"
                  filterable>
                <el-option
                    key="kz"
                    :label="$t('Qabilet.Казахский')"
                    value="kz">
                </el-option>
                <el-option
                    key="ru"
                    :label="$t('Qabilet.Русский')"
                    value="ru">
                </el-option>
              </el-select>
              <div class="header-additional__date">
                <div class="current-date mr-4">
                  {{ date }}
                </div>
                <div class="current-time">
                  {{ time }}
                </div>
              </div>
              <el-select
                  v-model="currentYear"
                  @change="setYear()"
                  :placeholder="$t('mynBala.Год')"
                  filterable>
                <el-option
                    key="2021"
                    label="2021"
                    value="2021">
                </el-option>
                <el-option
                    key="2022"
                    label="2022"
                    value="2022">
                </el-option>
                <el-option
                    key="2023"
                    label="2023"
                    value="2023">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>

      <section class="mb-statistic">
        <div class="container">
          <div class="mb-statistic__title-row">
            <div class="title">
              {{ $t('mynBala.мынбалаВЦифрах') }}
            </div>
            <div style="display: flex;">
              <div style="margin-right: 25px" class="select select--date select--region">


              </div>

            </div>
          </div>
          <div class="mb-statistic__cards-row">
            <div class="mb-card-first">
              <div class="mb-card-first__title">
                {{ $t('mynBala.данныеПоПодаче') }}
              </div>
              <div class="mb-card-first__content" v-if="currentYear == 2022">
                <div v-if="chartPatr" class="mb-card-first__chart">
                  <apexcharts type="radialBar" height="273" :options="chartOptions" :series="series"></apexcharts>
                </div>
                <div class="mb-card-first__info">
                  <div class="mb-card-first__info-2">
                    <span>{{ 266111 | Numerize }}</span>-
                    {{ $t('mynBala.Всегоучеников5-6 классов') }}
                  </div>
                  <div class="mb-card-first__info-3">
                    <span>{{ 146903 | Numerize }}</span>-
                    {{ $t('mynBala.Зарегистрировано') }}
                  </div>
                  <div class="mb-card-first__info-4">
                    <span>{{ 119208 | Numerize }}</span>-
                    {{ $t('mynBala.Незарегистрировано') }}
                  </div>
                </div>
              </div>
              <div class="mb-card-first__content" v-if="currentYear != 2021 && currentYear != 2022">
                <div v-if="chartPatr" class="mb-card-first__chart">
                  <apexcharts type="radialBar" height="273" :options="chartOptions" :series="series"></apexcharts>
                </div>
                <div class="mb-card-first__info">
                  <div class="mb-card-first__info-2">
                    <span>{{ participation[6].all.total | Numerize }}</span>-
                    {{ $t('mynBala.Всегоучеников') }}
                  </div>
                  <div class="mb-card-first__info-3">
                    <span>{{ participation[6].approved.total | Numerize }}</span>-
                    {{ $t('mynBala.Зарегистрировано') }}
                  </div>
                  <div class="mb-card-first__info-4">
                    <span>{{ participation[6].declined.total | Numerize }}</span>-
                    {{ $t('mynBala.Незарегистрировано') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row flex-wrap-mob">
              <div class="mb-card-second">
                <div class="mb-card-second__row mb-5">
                  <div class="mb-card-second__card mr-4">
                    <div class="mb-card-second__card-title">
                      1 {{ $t('mynBala.этап') }}
                    </div>
                    <div class="mb-card-second__chart">
                      <div
                          v-bind:style="{'height':((participation[6].approved.total)/(participation[6].approved.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-blue"></div>
                      <div
                          v-bind:style="{'height':((participation[6].second_stage_approved.total)/(participation[6].approved.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-green"></div>
                      <div
                          v-bind:style="{'height':((participation[6].second_stage_participants.total)/(participation[6].approved.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-yellow"></div>
                    </div>
                    <div class="chart-label-wrapper">
                      <div class="chart-label"><span
                          style="width:100%">{{
                          (participation[6].approved.total)| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].second_stage_approved.total | Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].second_stage_participants.total| Numerize
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-card-second__card">
                    <div class="mb-card-second__card-title">
                      2 {{ $t('mynBala.этап') }}
                    </div>
                    <div class="mb-card-second__chart">
                      <div
                          v-bind:style="{'height':((participation[6].second_stage_participants.total )/(participation[6].second_stage_participants.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-blue"></div>
                      <div
                          v-bind:style="{'height':((participation[6].finished.total)/(participation[6].second_stage_participants.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-green"></div>
                      <div
                          v-bind:style="{'height':((participation[6].winners.total)/(participation[6].second_stage_participants.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-yellow"></div>
                    </div>
                    <div class="chart-label-wrapper">
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].second_stage_participants.total| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].finished.total| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].winners.total| Numerize
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-card-second__desc justify-content-center">
                  <div class="mb-card-second__circle"></div>
                  <div>
                    <div>1 {{ $t('mynBala.этап') }} - {{ $t('mynBala.Финалисты') }}</div>
                    <div>2 {{ $t('mynBala.этап') }} - {{ $t('mynBala.Победители') }}</div>
                  </div>
                </div>
                <div class="mb-card-second__desc-row">
                  <div class="mb-card-second__desc justify-content-center">
                    <div class="mb-card-second__circle mb-card-second__circle--blue"></div>
                    <div>
                      {{ $t('mynBala.Допущеноктестированию') }}
                    </div>
                  </div>
                  <div class="mb-card-second__desc justify-content-center">
                    <div class="mb-card-second__circle mb-card-second__circle--green"></div>
                    <div>
                      {{ $t('mynBala.Прошлитестирование') }}

                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-card-third" v-if="currentYear == 2022">
                <div class="mb-card-third__row mb-3">
                  <div class="mb-card-third__card">
                    <div class="mb-card-third__card-title">
                      {{ $t('mynBala.РаспределениесСШОД') }}
                    </div>
                    <div class="mb-card-third__chart">
                      <div
                          v-bind:style="{'height':(434/434)*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-blue"></div>
                      <div
                          v-bind:style="{'height':(297/434)*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-green"></div>
                      <div style="background-color:#F00070"
                           v-bind:style="{'height':(137/434)*100+'%'}"
                           class="chart-column-mynbala chart-column-mynbala-green"></div>

                    </div>
                    <div class="chart-label-wrapper">
                      <div class="chart-label"><span
                          style="width:100%">{{
                          434| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          297| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          137| Numerize
                        }}</span>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="mb-card-third__desc mb-3">
                  <div class="mb-card-third__circle mb-card-third__circle--blue"></div>
                  <div>
                    {{ $t('mynBala.Всего мест') }}
                  </div>
                </div>
                <div class="mb-card-third__desc mb-3">
                  <div class="mb-card-third__circle mb-card-third__circle--green"></div>
                  <div>
                    {{ $t('mynBala.ПодалиЗаявкиНаЗачисление') }}
                  </div>
                </div>
                <div class="mb-card-third__desc">
                  <div class="mb-card-third__circle mb-card-third__circle--pink"></div>
                  <div>
                    {{ $t('mynBala.свободныхМест') }}

                  </div>
                </div>
              </div>
              <div class="mb-card-third" v-if="currentYear != 2021 && currentYear != 2022">
                <div class="mb-card-third__row mb-3">
                  <div class="mb-card-third__card">
                    <div class="mb-card-third__card-title">
                      {{ $t('mynBala.РаспределениесСШОД') }}
                    </div>
                    <div class="mb-card-third__chart">
                      <div
                          v-bind:style="{'height':(enrolled.total/enrolled.total)*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-blue"></div>
                      <div
                          v-bind:style="{'height':(enrolled.applied/enrolled.total)*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-green"></div>
                      <div style="background-color:#F00070"
                           v-bind:style="{'height':(enrolled.free_places/enrolled.total)*100+'%'}"
                           class="chart-column-mynbala chart-column-mynbala-green"></div>

                    </div>
                    <div class="chart-label-wrapper">
                      <div class="chart-label"><span
                          style="width:100%">{{
                          enrolled.total | Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          enrolled.applied | Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          enrolled.free_places | Numerize
                        }}</span>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="mb-card-third__desc mb-3">
                  <div class="mb-card-third__circle mb-card-third__circle--blue"></div>
                  <div>
                    {{ $t('mynBala.Всего мест') }}
                  </div>
                </div>
                <div class="mb-card-third__desc mb-3">
                  <div class="mb-card-third__circle mb-card-third__circle--green"></div>
                  <div>
                    {{ $t('mynBala.ПодалиЗаявкиНаЗачисление') }}
                  </div>
                </div>
                <div class="mb-card-third__desc">
                  <div class="mb-card-third__circle mb-card-third__circle--pink"></div>
                  <div>
                    {{ $t('mynBala.свободныхМест') }}

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section class="mb-stat-region">
        <div class="container">
          <div class="title">
            {{ $t('mynBala.мынбалаВЦифрах') }}


          </div>

          <div class="mb-stat-region__table-block" v-if="currentYear == 2022">

            <table class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--main table-striped">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="3" class="mb-stat-region-table__header">№</td>
                <td rowspan="3" class="mb-stat-region-table__header">{{ $t('mynBala.Показатели') }}</td>
                <th colspan="5" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">5
                  {{ $t('mynBala.класс') }}
                </th>
                <th colspan="5" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">6
                  {{ $t('mynBala.класс') }}
                </th>
                <td rowspan="3" class="mb-stat-region-table__header">{{ $t('mynBala.ОбщийИтог') }}</td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.РусскийЯзыкСдачиТеста') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Казахскийязыксдачитеста') }}</th>
                <th colspan="2" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Пол') }}
                </th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Итого') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.РусскийЯзыкСдачиТеста') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Казахскийязыксдачитеста') }}</th>
                <th colspan="2" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Пол') }}
                </th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Итого') }}</th>
              </tr>
              <tr class="mb-stat-region-table__header-3">
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Мужской') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Женский') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Мужской') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Женский') }}</th>
              </tr>

              <tr class="mb-stat-region-table__row">
                <td scope="row">1</td>
                <td>{{ $t('mynBala.Зарегистрировано') }}</td>
                <td>{{ 14130 | Numerize }}</td>
                <td>{{ 60962 | Numerize }}</td>
                <td>{{ 34812 | Numerize }}</td>
                <td>{{ 39367 | Numerize }}</td>
                <td>{{ 75092 | Numerize }}</td>
                <td>{{ 13388 | Numerize }}</td>
                <td>{{ 58424 | Numerize }}</td>
                <td>{{ 33344 | Numerize }}</td>
                <td>{{ 37577 | Numerize }}</td>
                <td>{{ 71812 | Numerize }}</td>
                <td>{{ 71812 + 75092 | Numerize }}</td>
              </tr>

              <tr class="mb-stat-region-table__row-main">
                <td scope="row">2</td>
                <td>{{ $t('mynBala.ДопущеноК1ЭтапуТестирования') }}</td>
                <td>{{ 12565 | Numerize }}</td>
                <td>{{ 55388 | Numerize }}</td>
                <td>{{ 31150 | Numerize }}</td>
                <td>{{ 36003 | Numerize }}</td>
                <td>{{ 67953  | Numerize }}</td>
                <td>{{ 11884 | Numerize }}</td>
                <td>{{ 52640 | Numerize }}</td>
                <td>{{ 29553 | Numerize }}</td>
                <td>{{ 34202 | Numerize }}</td>
                <td>{{ 64532 | Numerize }}</td>
                <td>
                  {{
                    132485| Numerize
                  }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3</td>
                <td>{{ $t('mynBala.ДопущеноК2ЭтапуТестирования') }}</td>
                <td>{{ 441 | Numerize }}</td>
                <td>{{ 2259 | Numerize }}</td>
                <td>{{ 1298 | Numerize }}</td>
                <td>{{ 1473 | Numerize }}</td>
                <td>{{ 2750 | Numerize }}</td>
                <td>{{ 332 | Numerize }}</td>
                <td>{{ 1968| Numerize }}</td>
                <td>{{ 1093 | Numerize }}</td>
                <td>{{ 1136 | Numerize }}</td>
                <td>{{ 2250  | Numerize }}</td>
                <td>{{ 2750 + 2250| Numerize }}</td>
              </tr>

              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3</td>
                <td>Приняли участие во 2 этапе тестирования</td>
                <td>{{ 329 | Numerize }}</td>
                <td>{{ 2211 | Numerize }}</td>
                <td>{{ 1107 | Numerize }}</td>
                <td>{{ 1433 | Numerize }}</td>
                <td>{{ 2540 | Numerize }}</td>
                <td>{{ 220 | Numerize }}</td>
                <td>{{ 1954| Numerize }}</td>
                <td>{{ 961 | Numerize }}</td>
                <td>{{ 1213 | Numerize }}</td>
                <td>{{ 2174  | Numerize }}</td>
                <td>{{ 4714| Numerize }}</td>
              </tr>

              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3</td>
                <td>Победители олимпиады</td>
                <td>{{ 126 | Numerize }}</td>
                <td>{{ 439 | Numerize }}</td>
                <td>{{ 240 | Numerize }}</td>
                <td>{{ 325 | Numerize }}</td>
                <td>{{ 565 | Numerize }}</td>
                <td>{{ 68 | Numerize }}</td>
                <td>{{ 367| Numerize }}</td>
                <td>{{ 202 | Numerize }}</td>
                <td>{{ 233 | Numerize }}</td>
                <td>{{ 435  | Numerize }}</td>
                <td>{{ 1000| Numerize }}</td>
              </tr>

            </table>
          </div>
          <div class="mb-stat-region__table-block" v-if="currentYear != 2021 && currentYear != 2022">

            <table class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--main table-striped">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="3" class="mb-stat-region-table__header">№</td>
                <td rowspan="3" class="mb-stat-region-table__header">{{ $t('mynBala.Показатели') }}</td>
                <th colspan="5" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">6
                  {{ $t('mynBala.класс') }}
                </th>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.РусскийЯзыкСдачиТеста') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Казахскийязыксдачитеста') }}</th>
                <th colspan="2" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Пол') }}
                </th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Итого') }}</th>
              </tr>
              <tr class="mb-stat-region-table__header-3">
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Мужской') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Женский') }}</th>
              </tr>

              <tr class="mb-stat-region-table__row-main">
                <td scope="row">1</td>
                <td>{{ $t('mynBala.Зарегистрировано') }}</td>
                <td>{{ participation[6].all.rus | Numerize }}</td>
                <td>{{ participation[6].all.kaz | Numerize }}</td>
                <td>{{ participation[6].all.male | Numerize }}</td>
                <td>{{ participation[6].all.female | Numerize }}</td>
                <td>{{ participation[6].all.total | Numerize }}</td>
              </tr>

              <tr class="mb-stat-region-table__row-main">
                <td scope="row">2</td>
                <td>{{ $t('mynBala.ДопущеноК1ЭтапуТестирования') }}</td>
                <td>{{ participation[6].first_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.male | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.female | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.total | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3</td>
                <td>{{ $t('mynBala.ДопущеноК2ЭтапуТестирования') }}</td>
                <td>{{ participation[6].second_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.male | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.female | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.total | Numerize }}</td>
              </tr>

              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3</td>
                <td>Приняли участие во 2 этапе тестирования</td>
                <td>{{ participation[6].finished.rus | Numerize }}</td>
                <td>{{ participation[6].finished.kaz | Numerize }}</td>
                <td>{{ participation[6].finished.male | Numerize }}</td>
                <td>{{ participation[6].finished.female | Numerize }}</td>
                <td>{{ participation[6].finished.total | Numerize }}</td>
              </tr>

              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3</td>
                <td>Победители олимпиады</td>
                <td>{{ participation[6].winners.rus | Numerize }}</td>
                <td>{{ participation[6].winners.kaz | Numerize }}</td>
                <td>{{ participation[6].winners.male | Numerize }}</td>
                <td>{{ participation[6].winners.female | Numerize }}</td>
                <td>{{ participation[6].winners.total | Numerize }}</td>
              </tr>

            </table>
          </div>
          <div v-if="false" class="mb-stat-region__table-block">

            <table class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--main table-striped">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="3" class="mb-stat-region-table__header">№</td>
                <td rowspan="3" class="mb-stat-region-table__header">{{ $t('mynBala.Показатели') }}</td>
                <th colspan="5" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">5
                  {{ $t('mynBala.класс') }}
                </th>
                <th colspan="5" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">6
                  {{ $t('mynBala.класс') }}
                </th>
                <td rowspan="3" class="mb-stat-region-table__header">{{ $t('mynBala.ОбщийИтог') }}</td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.РусскийЯзыкСдачиТеста') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Казахскийязыксдачитеста') }}</th>
                <th colspan="2" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Пол') }}
                </th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Итого') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.РусскийЯзыкСдачиТеста') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Казахскийязыксдачитеста') }}</th>
                <th colspan="2" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Пол') }}
                </th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Итого') }}</th>
              </tr>
              <tr class="mb-stat-region-table__header-3">
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Мужской') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Женский') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Мужской') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Женский') }}</th>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">1</td>
                <td>{{ $t('mynBala.ПоступилоЗаявок') }}</td>
                <td>{{ participation[5].all.rus | Numerize }}</td>
                <td>{{ participation[5].all.kaz | Numerize }}</td>
                <td>{{ participation[5].all.male | Numerize }}</td>
                <td>{{ participation[5].all.female | Numerize }}</td>
                <td>{{ participation[5].all.total | Numerize }}</td>
                <td>{{ participation[6].all.rus | Numerize }}</td>
                <td>{{ participation[6].all.kaz | Numerize }}</td>
                <td>{{ participation[6].all.female | Numerize }}</td>
                <td>{{ participation[6].all.male | Numerize }}</td>
                <td>{{ participation[6].all.total | Numerize }}</td>
                <td>{{ participation[6].all.total + participation[5].all.total | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row">
                <td scope="row">1.1</td>
                <td>{{ $t('mynBala.ПодтвержденоЗаявок') }}</td>
                <td>{{ participation[5].approved.rus | Numerize }}</td>
                <td>{{ participation[5].approved.kaz | Numerize }}</td>
                <td>{{ participation[5].approved.male | Numerize }}</td>
                <td>{{ participation[5].approved.female | Numerize }}</td>
                <td>{{ participation[5].approved.total | Numerize }}</td>
                <td>{{ participation[6].approved.rus | Numerize }}</td>
                <td>{{ participation[6].approved.kaz | Numerize }}</td>
                <td>{{ participation[6].approved.female | Numerize }}</td>
                <td>{{ participation[6].approved.male | Numerize }}</td>
                <td>{{ participation[6].approved.total | Numerize }}</td>
                <td>{{ participation[6].approved.total + participation[5].approved.total | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row">
                <td scope="row">1.2</td>
                <td>{{ $t('mynBala.Отклонено заявок') }}</td>
                <td>{{ participation[5].declined.rus | Numerize }}</td>
                <td>{{ participation[5].declined.kaz | Numerize }}</td>
                <td>{{ participation[5].declined.male | Numerize }}</td>
                <td>{{ participation[5].declined.female | Numerize }}</td>
                <td>{{ participation[5].declined.total | Numerize }}</td>
                <td>{{ participation[6].declined.rus | Numerize }}</td>
                <td>{{ participation[6].declined.kaz | Numerize }}</td>
                <td>{{ participation[6].declined.female | Numerize }}</td>
                <td>{{ participation[6].declined.male | Numerize }}</td>
                <td>{{ participation[6].declined.total | Numerize }}</td>
                <td>{{ participation[6].declined.total + participation[5].declined.total | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">2</td>
                <td>{{ $t('mynBala.ДопущеноК1ЭтапуТестирования') }}</td>
                <td>{{ participation[5].first_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.male | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.female | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.total | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.female | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.male | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.total | Numerize }}</td>
                <td>
                  {{
                    participation[6].first_stage_participants.total + participation[5].first_stage_participants.total| Numerize
                  }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3</td>
                <td>{{ $t('mynBala.ДопущеноК2ЭтапуТестирования') }}</td>
                <td>{{ participation[5].second_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.male| Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.female | Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.total | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.female | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.male | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.total | Numerize }}</td>
                <td>
                  {{
                    participation[6].second_stage_participants.total + participation[5].second_stage_participants.total | Numerize
                  }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3.1</td>
                <td>{{ $t('mynBala.Принялиучастиево2этапетестирования') }}</td>
                <td>{{ participation[5].second_stage_approved.rus | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.kaz | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.male | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.female | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.total | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.rus | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.kaz | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.female | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.male | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.total | Numerize }}</td>
                <td>
                  {{
                    participation[6].second_stage_approved.total + participation[5].second_stage_participants.total | Numerize
                  }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">4</td>
                <td>{{ $t('mynBala.Победители олимпиады') }}</td>
                <td>{{ participation[5].winners.rus | Numerize }}</td>
                <td>{{ participation[5].winners.kaz | Numerize }}</td>
                <td>{{ participation[5].winners.male | Numerize }}</td>
                <td>{{ participation[5].winners.female | Numerize }}</td>
                <td>{{ participation[5].winners.total | Numerize }}</td>
                <td>{{ participation[6].winners.rus | Numerize }}</td>
                <td>{{ participation[6].winners.kaz | Numerize }}</td>
                <td>{{ participation[6].winners.female | Numerize }}</td>
                <td>{{ participation[6].winners.male | Numerize }}</td>
                <td>{{ participation[6].winners.total | Numerize }}</td>
                <td>{{ participation[6].winners.total + participation[5].winners.total| Numerize }}</td>
              </tr>
            </table>
          </div>

        </div>
      </section>

      <section class="mb-rating">
        <div class="container">
          <div class="title">
            {{ $t('mynBala.заголовок') }}
          </div>
          <div class="mb-rating__sub-title">
            {{ $t('mynBala.РейтингпоКазахстану') }}
          </div>
          <div class="mb-rating__table-block mb-stat-region__table-block">
            <table style="text-align: center!important;" class="rating-table table table-striped rating-table--kz">
              <thead>
              <tr>
                <th scope="col" class="rating-table__header rating-table__header-helper-2">
                  <div>№</div>
                </th>
                <th scope="col" class="rating-table__header rating-table__header-helper-2">
                  <div>{{ $t('mynBala.Область') }}</div>
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevert('number')" scope="col"
                    class="rating-table__header rating-table__header-helper">
                  <div>{{ $t('mynBala.Зарегестрировано') }}</div>


                </th>
                <th style="cursor: pointer ;user-select: none" @click="regionsRatingRevert('first_stage')" scope="col"
                    class="rating-table__header rating-table__header-helper">
                  <div>{{ $t('mynBala.Прошло 1 этап теста') }}</div>
                </th>
                <th style="cursor: pointer ;user-select: none" @click="regionsRatingRevert('first_stage')" scope="col"
                    class="rating-table__header rating-table__header-helper">
                  <div>{{ $t('mynBala.Прошло 2 этап теста') }}</div>
                </th>
                <th style="cursor: pointer ;user-select: none" @click="regionsRatingRevert('first_stage')" scope="col"
                    class="rating-table__header rating-table__header-helper">
                  <div>Зачислено в СШОД</div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in regionsRating" :key="index+4">
                <td scope="row">{{ Number.isNaN(+index) === true ? getNumber(index) : index }}</td>
                <td v-if="locale=='ru'">{{ item.ru }}</td>
                <td v-if="locale=='kz'">{{ item.kz }}</td>
                <td>{{ item.number | Numerize }}</td>
                <td>{{ item.first_stage | Numerize }}</td>
                <td>{{ item.second_stage | Numerize }}</td>
                <td>{{ item.enrolled | Numerize }}</td>
              </tr>
              </tbody>
            </table>
          </div>


          <div class="d-flex flex-row align-items-center mb-4" v-if="currentYear == 2022">
            <div class="mb-rating__sub-title m-0">
              {{ $t('mynBala.РейтингШкол') }}
            </div>
            <div class="select select--region ml-5">
              <el-select
                  :disabled="filterLoading"
                  @change="filterRegionSchool"
                  :placeholder="$t('Qabilet.Регион')"
                  v-model="region_id_school"
                  filterable>
                <el-option
                    v-for="item in regions"
                    :key="item.id+5"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="mb-rating__table-block mb-rating__table-block--schools" v-if="currentYear == 2022">
            <table class="rating-table table table-striped rating-table--schools">
              <thead>
              <tr>
                <th scope="col" class="rating-table__header ">№</th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Название') }}
                </th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Область') }}
                </th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Район') }}
                </th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Населенныйпункт') }}
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevertSchool('number')" scope="col"
                    class="rating-table__header rating-table__header-helper">{{ $t('mynBala.Зарегестрировано') }}
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevertSchool('first_stage')"
                    scope="col"
                    class="rating-table__header rating-table__header-helper">{{ $t('mynBala.Прошло 1 этап теста') }}
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevertSchool('first_stage')"
                    scope="col"
                    class="rating-table__header rating-table__header-helper">Прошло 2 этап теста
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevertSchool('first_stage')"
                    scope="col"
                    class="rating-table__header rating-table__header-helper">Победители
                </th>

              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in schools" :key="index+6">
                <td scope="row">{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.region }}</td>
                <td>{{ item.city }}</td>
                <td>{{ item.locality }}</td>
                <td class="text-center">{{ item.number | Numerize }}</td>
                <td class="text-center">{{ item.first_stage | Numerize }}</td>
                <td class="text-center">{{ item.second_stage | Numerize }}</td>
                <td class="text-center">{{ item.winners| Numerize }}</td>
              </tr>

              </tbody>
            </table>
          </div>
          <nav class="tasks__pagination" v-if="currentYear == 2022">
            <el-pagination
                :disabled="filterLoading"
                :pager-count="11"
                :current-page='statsCurrentPage'
                @current-change="statsFilter"
                layout="prev, pager, next"
                :total="meta*10">
            </el-pagination>
          </nav>

          <div class="d-flex flex-row align-items-center mb-4" v-if="currentYear != 2022">
            <div class="mb-rating__sub-title m-0">
              {{ $t('mynBala.РейтингШкол') }}
            </div>
            <div class="select select--region ml-5">
              <el-select
                  :disabled="filterLoading"
                  @change="filterRegionSchool"
                  :placeholder="$t('Qabilet.Регион')"
                  v-model="region_id_school"
                  filterable>
                <el-option
                    v-for="item in regions"
                    :key="item.id+5"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="mb-rating__table-block mb-rating__table-block--schools" v-if="currentYear != 2022">
            <table class="rating-table table table-striped rating-table--schools">
              <thead>
              <tr>
                <th scope="col" class="rating-table__header ">№</th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Название') }}
                </th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Область') }}
                </th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Район') }}
                </th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Населенныйпункт') }}
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevertSchool2023('number')" scope="col"
                    class="rating-table__header rating-table__header-helper">{{ $t('mynBala.Зарегестрировано') }}
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevertSchool2023('first_stage')"
                    scope="col"
                    class="rating-table__header rating-table__header-helper">{{ $t('mynBala.Прошло 1 этап теста') }}
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevertSchool2023('first_stage')"
                    scope="col"
                    class="rating-table__header rating-table__header-helper">Прошло 2 этап теста
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevertSchool2023('first_stage')"
                    scope="col"
                    class="rating-table__header rating-table__header-helper">Победители
                </th>

              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in schools" :key="index+6">
                <td scope="row">{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.region }}</td>
                <td>{{ item.city }}</td>
                <td>{{ item.locality }}</td>
                <td class="text-center">{{ item.number | Numerize }}</td>
                <td class="text-center">{{ item.first_stage | Numerize }}</td>
                <td class="text-center">{{ item.second_stage | Numerize }}</td>
                <td class="text-center">{{ item.winners| Numerize }}</td>
              </tr>

              </tbody>
            </table>
          </div>
          <nav class="tasks__pagination" v-if="currentYear != 2022">
            <el-pagination
                :disabled="filterLoading"
                :pager-count="11"
                :current-page='statsCurrentPage'
                @current-change="statsFilter2023"
                layout="prev, pager, next"
                :total="meta*10">
            </el-pagination>
          </nav>

          <div class="select select--region">

          </div>
          <div class="mb-stat-region__table-block" v-if="currentYear == 2022">
            <table class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--members">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="1" colspan="1"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue"></td>
                <td rowspan="1" colspan="9" class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Количествоучастниковпогендерномупризнаку') }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th rowspan="2" class="mb-stat-region-table__header mb-stat-region-table__header--blue"></th>
                <th rowspan="1" colspan="2" class="mb-stat-region-table__header"> {{
                    $t('mynBala.Зарегестрировано')
                  }}
                </th>
                <th rowspan="1" colspan="2" scope="colgroup" class="mb-stat-region-table__header">
                  {{ $t('mynBala.Прошло 1 этап теста') }}
                </th>
                <th rowspan="1" colspan="2" class="mb-stat-region-table__header">{{
                    $t('mynBala.Прошло 2 этап теста')
                  }}
                </th>
                <th rowspan="1" colspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.ЗачисленовСШОД') }}</th>
              </tr>
              <tr class="mb-stat-region-table__header-3">
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">Количество</td>
                <td v-if="genderStats.male_total">{{ 68708 | Numerize }}</td>
                <td v-if="genderStats.female_total">{{ 77598 | Numerize }}</td>
                <td v-if="genderStats.male_first_stage">{{ 61291 | Numerize }}</td>
                <td v-if="genderStats.female_first_stage">{{ 70919 | Numerize }}</td>
                <td v-if="genderStats.male_second_stage">{{ 2069| Numerize }}</td>
                <td v-if="genderStats.female_second_stage">{{ 2645 | Numerize }}</td>
                <td v-if="genderStats.male_enrolled">{{ 156 | Numerize }}</td>
                <td v-if="genderStats.female_enrolled">{{ 131 | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">Процент</td>
                <td v-if="genderStats.male_total">{{ 47 }}%</td>
                <td v-if="genderStats.female_total">{{
                  53
                  }}%
                </td>
                <td v-if="genderStats.male_first_stage">{{
                    46.3
                  }}%
                </td>
                <td v-if="genderStats.female_first_stage">
                  {{ 53.6 }}%
                </td>
                <td v-if="genderStats.male_second_stage">
                  {{ 43.9 }}%
                </td>
                <td v-if="genderStats.female_second_stage">
                  {{ 56.1 }}%
                </td>
                <td v-if="genderStats.male_enrolled">{{
                  54.3
                  }}%
                </td>
                <td v-if="genderStats.female_enrolled">{{
                   45.6
                  }}%
                </td>
              </tr>
            </table>
          </div>
          <div class="mb-stat-region__table-block" v-if="currentYear != 2022">
            <table class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--members">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="1" colspan="1"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue"></td>
                <td rowspan="1" colspan="9" class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Количествоучастниковпогендерномупризнаку') }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th rowspan="2" class="mb-stat-region-table__header mb-stat-region-table__header--blue"></th>
                <th rowspan="1" colspan="2" class="mb-stat-region-table__header"> {{
                    $t('mynBala.Зарегестрировано')
                  }}
                </th>
                <th rowspan="1" colspan="2" scope="colgroup" class="mb-stat-region-table__header">
                  {{ $t('mynBala.Прошло 1 этап теста') }}
                </th>
                <th rowspan="1" colspan="2" class="mb-stat-region-table__header">{{
                    $t('mynBala.Прошло 2 этап теста')
                  }}
                </th>
                <th rowspan="1" colspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.ЗачисленовСШОД') }}</th>
              </tr>
              <tr class="mb-stat-region-table__header-3">
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">Количество</td>
                <td v-if="genderStats.male_total">{{ genderStats.male_total | Numerize }}</td>
                <td v-if="genderStats.female_total">{{ genderStats.female_total | Numerize }}</td>
                <td v-if="genderStats.male_first_stage">{{ genderStats.male_first_stage | Numerize }}</td>
                <td v-if="genderStats.female_first_stage">{{ genderStats.female_first_stage | Numerize }}</td>
                <td v-if="genderStats.male_second_stage">{{ genderStats.male_second_stage| Numerize }}</td>
                <td v-if="genderStats.female_second_stage">{{ genderStats.female_second_stage | Numerize }}</td>
                <td v-if="genderStats.male_enrolled">{{ genderStats.male_enrolled | Numerize }}</td>
                <td v-if="genderStats.female_enrolled">{{ genderStats.female_enrolled | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">Процент</td>
                <td v-if="genderStats.male_total_percent">{{ genderStats.male_total_percent }}%</td>
                <td v-if="genderStats.female_total_percent">{{
                    genderStats.female_total_percent
                  }}%
                </td>
                <td v-if="genderStats.male_first_stage_percent">{{
                    genderStats.male_first_stage_percent
                  }}%
                </td>
                <td v-if="genderStats.female_first_stage_percent">
                  {{ genderStats.female_first_stage_percent }}%
                </td>
                <td v-if="genderStats.male_second_stage_percent">
                  {{ genderStats.male_second_stage_percent }}%
                </td>
                <td v-if="genderStats.female_second_stage_percent">
                  {{ genderStats.female_second_stage_percent }}%
                </td>
                <td v-if="genderStats.male_enrolled_percent">{{
                    genderStats.male_enrolled_percent
                  }}%
                </td>
                <td v-if="genderStats.female_enrolled_percent">{{
                    genderStats.female_enrolled_percent
                  }}%
                </td>
              </tr>
            </table>
          </div>

          <div class="d-flex flex-row align-items-center mb-4">
            <div class="mb-rating__sub-title m-0">
              {{ $t('mynBala.Тесты1этапаолимпиадыМынбала') }}
            </div>
            <div class="select select--region ml-5">

            </div>
          </div>
          <div class="mb-rating__table-block mb-rating__table-block--schools">
            <table v-if="filterStageYear==2021"
                   class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--tests">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="2" colspan="1"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue"></td>
                <td rowspan="1" colspan="10" class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Количествовопросовпопредметам') }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th v-for="item in stage2021_1.subjects" :key="item+10" rowspan="1"
                    class="mb-stat-region-table__header">
                  {{ item | Numerize }}
                </th>
              </tr>
              <div v-for="(item,index) in stage2021_1.data" :key="index+11" style="display:contents">
                <tr rowspan="1" class="mb-stat-region-table__row-main">
                  <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">
                    <span v-if="locale=='ru'">{{ item.quiz.title_ru }}</span>
                    <span v-if="locale=='kz'">{{ item.quiz.title_kz }}</span>
                  </td>
                  <td v-for="(quest,index) in item.questions" :key="index+12" rowspan="1"
                      class="mb-stat-region-table__header">
                    {{ quest ? quest.number : '' | Numerize }}
                  </td>
                </tr>
              </div>
            </table>
            <table v-if="filterStageYear!=2021"
                   class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--tests">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="2" colspan="1"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue"></td>
                <td rowspan="1" colspan="10" class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Количествовопросовпопредметам') }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th v-for="item in stage[1].subjects" :key="item+13" rowspan="1"
                    class="mb-stat-region-table__header">
                  {{ item }}
                </th>
              </tr>
              <div v-for="(item,index) in stage[1].data" :key="item+index+14" style="display:contents">
                <tr v-if="item!=null" rowspan="1" class="mb-stat-region-table__row-main">
                  <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">
                    <span v-if="locale=='ru'">{{ item.quiz.title_ru }}</span>
                    <span v-if="locale=='kz'">{{ item.quiz.title_kz }}</span>
                  </td>
                  <td v-for="(quest,index) in item.questions" :key="quest+index+15" rowspan="1"
                      class="mb-stat-region-table__header">
                    {{ quest ? quest.number : '' | Numerize }}
                  </td>
                </tr>
              </div>
            </table>
          </div>

          <div class="d-flex flex-row align-items-center mb-4">
            <div class="mb-rating__sub-title m-0">
              {{ $t('mynBala.Тесты2этапаолимпиадыМынбала') }}
            </div>
            <div class="select select--region ml-5">

            </div>
          </div>
          <div class="mb-rating__table-block mb-rating__table-block--schools">
            <table v-if="filterStageYear!=2021"
                   class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--tests">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="2" colspan="1"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue"></td>
                <td rowspan="1" colspan="10" class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Количествовопросовпопредметам') }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th v-for="item in stage[2].subjects" :key="item+17" rowspan="1"
                    class="mb-stat-region-table__header">
                  {{ item }}
                </th>
              </tr>
              <div v-for="(item,index) in stage[2].data" :key="item+index+18" style="display:contents">
                <tr v-if="item!=null" rowspan="1" class="mb-stat-region-table__row-main">
                  <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">
                    <span v-if="locale=='ru'">{{ item.quiz.title_ru }}</span>
                    <span v-if="locale=='kz'">{{ item.quiz.title_kz }}</span>
                  </td>
                  <td v-for="(quest,index) in item.questions" :key="quest + index +19" rowspan="1"
                      class="mb-stat-region-table__header">
                    {{ quest ? quest.number : '' | Numerize }}
                  </td>
                </tr>
              </div>
            </table>
            <table v-if="filterStageYear==2021"
                   class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--tests">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="2" colspan="1"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue"></td>
                <td rowspan="1" colspan="10" class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Количествовопросовпопредметам') }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th v-for="item in  stage2021_2.subjects" :key="item+20" rowspan="1"
                    class="mb-stat-region-table__header">
                  {{ item }}
                </th>
              </tr>
              <div v-for="(item,index) in stage2021_2.data" :key="index+21" style="display:contents">
                <tr rowspan="1" class="mb-stat-region-table__row-main">
                  <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">
                    <span v-if="locale=='ru'">{{ item.quiz.title_ru }}</span>
                    <span v-if="locale=='kz'">{{ item.quiz.title_kz }}</span>
                  </td>
                  <td v-for="(quest,index) in item.questions" :key="index+22" rowspan="1"
                      class="mb-stat-region-table__header">
                    {{ quest ? quest.number : '' | Numerize }}
                  </td>
                </tr>
              </div>
            </table>
          </div>
        </div>
      </section>

      <section class="sshod">
        <div class="container">
          <div class="title">
            {{ $t('mynBala.РаспределениесСШОД') }}
          </div>
          <div class="sshod__row">
            <div v-if="sshod__chartSeries.length" class="sshod__chart">
              <div id="chart">
                <apexcharts type="donut" height="543" :options="sshod__chartOptions"
                            :series="sshod__chartSeries"></apexcharts>
              </div>
            </div>
            <div class="sshod__cols">
              <div class="sshod__col-cards">
                <div class="sshod__card">
                  <div class="sshod__card-title">
                    {{ $t('mynBala.ОбщееКоличествоСвободныхМест') }}
                  </div>
                  <div class="sshod__card-amount">
                    {{ sshod.total_places | Numerize }}
                  </div>
                </div>
                <div class="sshod__card">
                  <div class="sshod__card-title">
                    {{ $t('mynBala.Свободныхместв7классесказахскимязыкомобучения') }}
                  </div>
                  <div class="sshod__card-amount">
                    {{ sshod.total_places_kaz | Numerize }}
                  </div>
                </div>
                <div class="sshod__card">
                  <div class="sshod__card-title">
                    {{ $t('mynBala.Свободныхместв7классесрусскимязыкомобучения') }}
                  </div>
                  <div class="sshod__card-amount">
                    {{ sshod.total_places_rus | Numerize }}
                  </div>
                </div>
              </div>
              <div class="sshod__col-card">
                <ul class="sshod__regions-list" style="cursor:pointer!important">
                  <li v-bind:class="{ 'sshod__region-item': currentRegionChart=='' }" @click="filterChart('')">
                    {{ $t('Qabilet.ПоказатьВсе') }}
                  </li>
                  <li v-bind:class="{ 'sshod__region-item': currentRegionChart==region.id }"
                      @click="filterChart(region.id)" v-for="region in regions" :key="region.id+23">
                    <span>{{ region.id }}</span> {{ region.name }}
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="distribution-sshod">
        <div class="container">
          <div class="mb-rating__sub-title">
            {{ $t('mynBala.РаспределениевСШОДПобедителейолимпиады') }}
          </div>
          <div class="mb-rating__table-block mb-rating__table-block--schools">
            <table class="rating-table table table-striped rating-table--winners">
              <thead>
              <tr>
                <th scope="col" class="rating-table__header">{{ $t('Qabilet.Регион') }}</th>
                <th scope="col" class="rating-table__header"> {{ $t('mynBala.русский') }}</th>
                <th scope="col" class="rating-table__header"> {{ $t('mynBala.казахский') }}</th>
                <th scope="col" class="rating-table__header"> {{ $t('mynBala.Итого') }}</th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Мужской') }}</th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Женский') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in  regionPlaces" :key="index+24">
                <td scope="row">
                  <div>
                    {{ regions[index].name }}
                  </div>
                  <div>
                    {{ $t('mynBala.Подализаявкунапоступление') }}
                  </div>
                </td>
                <td>
                  <div>{{ item[index + 1].rus_all | Numerize }} {{ $t('mynBala.мест') }}</div>
                  <div>{{ item[index + 1].rus_applied | Numerize }} {{ $t('mynBala.чел') }}.</div>
                </td>
                <td>
                  <div>{{ item[index + 1].kaz_all }} {{ $t('mynBala.мест') }}</div>
                  <div>{{ item[index + 1].kaz_applied }} {{ $t('mynBala.чел') }}.</div>
                </td>
                <td>
                  <div>{{ item[index + 1].total | Numerize }} {{ $t('mynBala.мест') }}</div>
                  <div>{{ item[index + 1].total_applied | Numerize }} {{ $t('mynBala.чел') }}.</div>
                </td>
                <td>
                  <div>{{ item[index + 1].male | Numerize }} {{ $t('mynBala.чел') }}.</div>
                </td>
                <td>
                  <div>{{ item[index + 1].female  | Numerize }} {{ $t('mynBala.чел') }}.</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section class="olympiad-comparison">
        <div class="container">
          <div class="d-flex flex-row align-items-center mb-4">
            <div class="mb-rating__sub-title m-0 mr-3">
              {{ $t('mynBala.Сравнение_показателей_олимпиады_по_годам') }}
            </div>
            <div class="d-flex flex-row">
              <div class="select select--region mr-2">

              </div>
              <div class="select select--region">

              </div>
            </div>

          </div>

          <div class="olympiad-comparison__cards" v-if="!loadChart">
            <div class="comparison-card">
              <div class="comparison-card__title"> Зарегистрировались для участия</div>
              <div class="comparison-card__chart">
                <apexcharts type="bar" height="350" :options="chartOptionsYear" :series="seriesYearTotal"></apexcharts>
              </div>
            </div>

            <div class="comparison-card">
              <div class="comparison-card__title">{{ $t('mynBala.ПрошлиТест1ЭтапаОнлайн') }}</div>
              <div class="comparison-card__chart">
                <apexcharts type="bar" height="350" :options="chartOptionsYear"
                            :series="seriesYearFirstStage"></apexcharts>
              </div>
            </div>
            <div class="comparison-card">
              <div class="comparison-card__title">{{ $t('mynBala.ПрошлиТест2ЭтапаОнлайн') }}</div>
              <div class="comparison-card__chart">
                <apexcharts type="bar" height="350" :options="chartOptionsYear"
                            :series="seriesYearSecondStage"></apexcharts>
              </div>
            </div>
            <div class="comparison-card">
              <div class="comparison-card__title">{{ $t('mynBala.РаспределениесСШОД') }}</div>
              <div class="comparison-card__chart">
                <apexcharts type="bar" height="350" :options="chartOptionsYear"
                            :series="seriesYearEnroled"></apexcharts>
              </div>
            </div>
          </div>


        </div>
      </section>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexcharts: VueApexCharts,
  },
  name: 'mynBala',
  data() {
    return {
      participation: {},
      genderStats: {},
      currentYear:2023,
      statsCurrentPage: 1,
      sortColumn: '',
      sortBy: 'desc',
      region_id_mynbalaNum: '',
      region_id_gender: 4,
      qabiletDateTo: '',
      qabiletDateFrom: '',
      filterLoading: true,
      schoolRatingRegionId: 4,
      meta: 1,
      CurrentPage: 1,
      yearFrom: '',
      yearTo: '',
      loading: false,
      filterStageYear: '',
      seriesYearTotal: [],
      region_id_school: 4,
      seriesYearFirstStage: [],
      seriesYearSecondStage: [],
      seriesYearEnroled: [],
      yearStatistics: {},
      enrolled: {},
      regionPlaces: [],
      currentRegionChart: '',
      years: [],
      chartPatr: true,
      stage2021_2: {},
      loadChart: false,
      regions: [],
      time: '',
      date: '',
      locale: this.$i18n.locale,
      chartOptionsYear: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        colors: ['#2F6BC0', '#2091F9', '#71BF4F', '#F0A400'],
        fill: {
          opacity: 1
        },
      },
      series: [0, 0, 0],
      chartOptions: {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '10px',
                show: false,
              },
              value: {
                fontSize: '30px',
                color: '#013B8F',
                show: true,
              }
            }
          }
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
        colors: ['#F0A400', '#71BF4F', '#2F6BC0'],
        labels: [this.$t('mynBala.ВсегоПоданоЗаявок'), this.$t('mynBala.подтвержденоЗаявок'), this.$t('mynBala.отказано')],
      },
      sshod: [],
      sshod__chartSeries: [0, 0, 0, 0],
      sshod__chartOptions: {
        colors: ['#2F6EE2'],
        legend: {
          show: false,
          position: 'bottom',

        },
        chart: {
          type: 'donut',
        },

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '30px',
                  fontWeight: 600,
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  style: {},
                  fontSize: '40px',
                  fontWeight: 600,
                  color: '#013B8F',
                  show: true,
                },
                total: {
                  show: true,
                  label: this.$t('Всегошкол'),
                  color: '#013B8F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
        labels: [],
      },

      loadingApi: {
        regions: false,
        regionPlaces: false,
        schoolPlaces: false,
        stage: false,
        gender: false,
        schools: false,
        regionsRating: false,
        participation: false,
        enrolled: false,
        yearStat: false,
        loadChart: false
      },
      tableRevertSchool: {
        number: '',
        first_stage: '',
        second_stage: '',
      },
      tableRevert: {
        number: '',
        first_stage: '',
        second_stage: '',
        enrolled: ''
      },
    }
  },
  mounted() {
    if (this.region_id == 0) {
      this.region_id = ''
    }

    if (localStorage.getItem('myn_bala_current_year')) {
      this.currentYear = JSON.parse(localStorage.getItem('myn_bala_current_year'))
    }
    // var currentYear = new Date().getFullYear()
    // var max = currentYear
    // for (var year = 2021; year <= max; year++) {
    //   this.years.push({'name': year, 'id': year})
    // }
    // this.qabiletDateFrom =this.years[this.years.length - 2].name
    // this.qabiletDateTo = this.years[this.years.length - 1].name
    // this.yearFrom = this.years[0].name
    // this.yearTo = this.years[this.years.length - 1].name

    var currentYear = new Date().getFullYear()
    var max = currentYear
    for (var year = 2021; year <= 2021; year++) {
      this.years.push({'name': year, 'id': year})
    }
    this.qabiletDateFrom = 2021
    this.qabiletDateTo = 2021
    this.yearFrom = 2021
    this.yearTo = 2021


    setInterval(this.clock, 1000);
    this.qabiletDateTo = this.qabiletDateFrom
    this.loadingApi.regions = true
    this.$http.get(window.mynbala + '/api/references/regions')
        .then((res) => {
          this.regions = res.body.data;
          this.loadingApi.regions = false
        })

    if (this.currentYear != 2022) {
      this.loadingApi.regionPlaces = true
      this.$http.get(window.mynbala + `/api/dashboard/region-places?from=${this.currentYear}-01-01&to=${this.currentYear}-12-31`)
          .then((res) => {
            this.regionPlaces = res.body
            this.loadingApi.regionPlaces = false
          })
    } else {
      this.loadingApi.regionPlaces = true
      this.$http.get(window.mynbala + `/api/dashboard/region-places?from=2022-01-01&to=2022-12-31`)
          .then((res) => {
            this.regionPlaces = [{
              "1": {
                "rus_all": "4",
                "kaz_all": "11",
                "rus_applied": 4,
                "kaz_applied": 11,
                "total": 15,
                "total_applied": 15,
                "female": 6,
                "male": 9
              }
            }, {
              "2": {
                "rus_all": "18",
                "kaz_all": "47",
                "rus_applied": 6,
                "kaz_applied": 43,
                "total": 65,
                "total_applied": 49,
                "female": 27,
                "male": 22
              }
            }, {
              "3": {
                "rus_all": "0",
                "kaz_all": "48",
                "rus_applied": 0,
                "kaz_applied": 28,
                "total": 48,
                "total_applied": 28,
                "female": 12,
                "male": 16
              }
            }, {
              "4": {
                "rus_all": "6",
                "kaz_all": "16",
                "rus_applied": 4,
                "kaz_applied": 8,
                "total": 22,
                "total_applied": 12,
                "female": 4,
                "male": 8
              }
            }, {
              "5": {
                "rus_all": 0,
                "kaz_all": 4,
                "rus_applied": 0,
                "kaz_applied": 4,
                "total": 4,
                "total_applied": 4,
                "female": 0,
                "male": 4
              }
            }, {
              "6": {
                "rus_all": "5",
                "kaz_all": "26",
                "rus_applied": 1,
                "kaz_applied": 21,
                "total": 31,
                "total_applied": 22,
                "female": 8,
                "male": 14
              }
            }, {
              "7": {
                "rus_all": 2,
                "kaz_all": 15,
                "rus_applied": 2,
                "kaz_applied": 15,
                "total": 17,
                "total_applied": 17,
                "female": 8,
                "male": 9
              }
            }, {
              "8": {
                "rus_all": 7,
                "kaz_all": 11,
                "rus_applied": 7,
                "kaz_applied": 11,
                "total": 18,
                "total_applied": 18,
                "female": 8,
                "male": 10
              }
            }, {
              "9": {
                "rus_all": "4",
                "kaz_all": "32",
                "rus_applied": 2,
                "kaz_applied": 27,
                "total": 36,
                "total_applied": 29,
                "female": 15,
                "male": 14
              }
            }, {
              "10": {
                "rus_all": "8",
                "kaz_all": "22",
                "rus_applied": 2,
                "kaz_applied": 10,
                "total": 30,
                "total_applied": 12,
                "female": 7,
                "male": 5
              }
            }, {
              "11": {
                "rus_all": 5,
                "kaz_all": 4,
                "rus_applied": 5,
                "kaz_applied": 4,
                "total": 9,
                "total_applied": 9,
                "female": 4,
                "male": 5
              }
            }, {
              "12": {
                "rus_all": 0,
                "kaz_all": 13,
                "rus_applied": 0,
                "kaz_applied": 13,
                "total": 6,
                "total_applied": 13,
                "female": 7,
                "male": 6
              }
            }, {
              "13": {
                "rus_all": 0,
                "kaz_all": 16,
                "rus_applied": 0,
                "kaz_applied": 11,
                "total": 41,
                "total_applied": 11,
                "female": 3,
                "male": 8
              }
            }, {
              "14": {
                "rus_all": 2,
                "kaz_all": 18,
                "rus_applied": 2,
                "kaz_applied": 12,
                "total": 20,
                "total_applied": 14,
                "female": 8,
                "male": 6
              }
            }, {
              "15": {
                "rus_all": 5,
                "kaz_all": 7,
                "rus_applied": 5,
                "kaz_applied": 3,
                "total": 12,
                "total_applied": 8,
                "female": 4,
                "male": 4
              }
            }, {
              "16": {
                "rus_all": "2",
                "kaz_all": "59",
                "rus_applied": 2,
                "kaz_applied": 18,
                "total": 61,
                "total_applied": 20,
                "female": 8,
                "male": 12
              }
            }, {
              "17": {
                "rus_all": 0,
                "kaz_all": 17,
                "rus_applied": 0,
                "kaz_applied": 6,
                "total": 17,
                "total_applied": 6,
                "female": 2,
                "male": 4
              }
            }];
            this.loadingApi.regionPlaces = false
          })
    }


    // Распределение в СШОД

    if (this.currentYear != 2022 && this.currentYear != 2021) {
      this.loadingApi.schoolPlaces = true
      this.$http.get(window.mynbala + `/api/dashboard/school-places?from=${this.currentYear}-01-01&to=${this.currentYear}-12-31`)
          .then((res) => {
            this.sshod = res.body
            this.sshod__chartSeries = []
            this.sshod__chartOptions.labels = []
            for (var x = 0; x < res.body.schools_by_regions.length; x++) {
              this.sshod__chartSeries.push(res.body.schools_by_regions[x].number)
              this.sshod__chartOptions.labels.push(res.body.schools_by_regions[x].name)
            }
            this.loadingApi.schoolPlaces = false
          })
    } else {
      this.loadingApi.schoolPlaces = true
      this.$http.get(window.mynbala + `/api/dashboard/school-places?from=2022-01-01&to=2022-12-31`)
          .then((res) => {
            this.sshod = res.body
            this.sshod__chartSeries = []
            this.sshod__chartOptions.labels = []
            for (var x = 0; x < res.body.schools_by_regions.length; x++) {
              this.sshod__chartSeries.push(res.body.schools_by_regions[x].number)
              this.sshod__chartOptions.labels.push(res.body.schools_by_regions[x].name)
            }
            this.loadingApi.schoolPlaces = false
          })
    }

    if (this.currentYear != 2022) {
      this.loadingApi.stage = true
      this.$http.get(window.mynbala + '/api/dashboard/first-stage?year=' + 2021)
          .then((res) => {
            this.filterStageYear = this.currentYear
            this.stage = res.body;
            delete this.stage[1].subjects[1]
            for (let i = 0; i < this.stage[1].data.length; i++) {
              delete this.stage[1].data[i].questions[1]
            }

            this.stage2021_1 = res.body[1]
            this.stage2021_1.data = [res.body[1].data[1], res.body[1].data[7], res.body[1].data[5], res.body[1].data[26], res.body[1].data[28], res.body[1].data[49]]
            this.stage2021_2 = res.body[2]
            this.stage2021_2.data = [res.body[2].data[5], res.body[2].data[6], res.body[2].data[9], res.body[2].data[10]]
            this.loadingApi.stage = false
          })
    } else {
      this.loadingApi.stage = true
      this.$http.get(window.mynbala + '/api/dashboard/first-stage?year=' + 2021)
          .then((res) => {
            this.filterStageYear = this.years[this.years.length - 1].name
            this.stage = res.body;
            this.stage2021_1 = res.body[1]
            this.stage2021_1.data = [res.body[1].data[0], res.body[1].data[0]]
            this.stage2021_2 = res.body[2]
            this.stage2021_2.data = [res.body[2].data[2], res.body[2].data[4], res.body[2].data[5], res.body[2].data[6]]
            this.loadingApi.stage = false
          })
    }

    //Количество участников по гендерному признаку

    if (this.currentYear != 2022) {
      this.loadingApi.gender = true
      this.$http.get(window.mynbala + `/api/dashboard/gender-statistics?from=${this.currentYear}-01-01&to=${this.currentYear}-12-31`)
          .then((res) => {
            const genderData = res.body
            const genderDataKeys = Object.keys(genderData)
            const newGenderData = {
              total : 0,
              female_total: 0,
              female_total_percent: 0,
              male_total: 0,
              male_total_percent: 0,
              female_first_stage: 0,
              female_first_stage_percent: 0,
              male_first_stage: 0,
              male_first_stage_percent: 0,
              female_second_stage: 0,
              female_second_stage_percent: 0,
              male_second_stage: 0,
              male_second_stage_percent: 0,
              female_enrolled: 0,
              female_enrolled_percent: 0,
              male_enrolled: 0,
              male_enrolled_percent: 0,
            }

            // Собираем данные по регионам вместе

            for (let i = 1; i <= genderDataKeys.length; i++) {
              newGenderData.total = newGenderData.total + genderData[i].total
              newGenderData.female_total = newGenderData.female_total + genderData[i].female_total.count
              newGenderData.male_total = newGenderData.male_total + genderData[i].male_total.count
              newGenderData.female_first_stage = newGenderData.female_first_stage + genderData[i].female_first_stage.count
              newGenderData.male_first_stage = newGenderData.male_first_stage + genderData[i].male_first_stage.count
              newGenderData.female_second_stage = newGenderData.female_second_stage + genderData[i].female_second_stage.count
              newGenderData.male_second_stage = newGenderData.male_second_stage + genderData[i].male_second_stage.count
              newGenderData.female_enrolled = newGenderData.female_enrolled + genderData[i].female_enrolled.count
              newGenderData.male_enrolled = newGenderData.male_enrolled + genderData[i].male_enrolled.count
            }

            // Добавляем процентное соотношение

            newGenderData.female_total_percent = Math.round((newGenderData.female_total / (newGenderData.female_total + newGenderData.male_total)) * 100)
            newGenderData.male_total_percent = 100 - newGenderData.female_total_percent
            newGenderData.female_first_stage_percent = Math.round((newGenderData.female_first_stage / (newGenderData.female_first_stage + newGenderData.male_first_stage)) * 100)
            newGenderData.male_first_stage_percent = 100 - newGenderData.female_first_stage_percent
            newGenderData.female_second_stage_percent = Math.round((newGenderData.female_second_stage / (newGenderData.female_second_stage + newGenderData.male_second_stage)) * 100)
            newGenderData.male_second_stage_percent = 100 - newGenderData.female_second_stage_percent
            newGenderData.female_enrolled_percent = Math.round((newGenderData.female_enrolled / (newGenderData.female_enrolled + newGenderData.male_enrolled)) * 100)
            newGenderData.male_enrolled_percent = 100 - newGenderData.female_enrolled_percent
            this.genderStats = newGenderData
            this.loadingApi.gender = false
          })
    } else {
      this.loadingApi.gender = true
      this.$http.get(window.mynbala + `/api/dashboard/gender-statistics?region=4&from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.genderStats = {
              "total": 4714,
              "female_total": {"count": 2642, "percent": 56},
              "male_total": {"count": 2079, "percent": 44},
              "female_first_stage": {"count": 2789, "percent": 54.15533980582524},
              "male_first_stage": {"count": 2361, "percent": 45.84466019417476},
              "female_second_stage": {"count": 2634, "percent": 59.07172995780591},
              "male_second_stage": {"count": 2062, "percent": 40.92827004219409},
              "female_enrolled": {"count": 9, "percent": 69.23076923076923},
              "male_enrolled": {"count": 4, "percent": 30.76923076923077}
            };
            this.loadingApi.gender = false
          })
    }

    if (this.currentYear != 2022) {
      this.loadingApi.schools = true
      this.$http.get(window.mynbala + `/api/dashboard/schools-rating?region=4&from=${this.currentYear}-01-01&to=${this.currentYear}-12-31`)
          .then((res) => {
            this.meta = res.body.last_page
            this.CurrentPage = res.body.current_page;
            this.schools = res.body.data;
            this.filterLoading = false
            this.loadingApi.schools = false
          })
    } else {
      this.loadingApi.schools = true
      this.$http.get(window.mynbala + `/api/dashboard/schools-rating?region=4&from=2022-01-01&to=2022-12-31`)
          .then((res) => {
            this.meta = res.body.last_page
            this.CurrentPage = res.body.current_page;
            this.schools = res.body.data;
            this.filterLoading = false
            this.loadingApi.schools = false
          })
    }


    if (this.currentYear == 2023) {
      this.loadingApi.regionsRating = true
      this.$http.get(window.mynbala + `/api/dashboard/regions-rating?from=${this.currentYear}-01-01&to=${this.currentYear}-12-31`)
          .then((res) => {
            this.regionsRating = res.body;
            this.loadingApi.regionsRating = false
          })
    } else {
      this.loadingApi.regionsRating = true
      this.$http.get(window.mynbala + `/api/dashboard/regions-rating?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.regionsRating = {
              "1": {
                "number": 6920,
                "first_stage": 6368,
                "second_stage": 129,
                "id": 4,
                "ru": "Акмолинская область",
                "kz": "Ақмола облысы",
                "enrolled": 12
              },
              "2": {
                "number": 5292,
                "first_stage": 4246,
                "second_stage": 117,
                "id": 5,
                "ru": "Актюбинская область",
                "kz": "Ақтөбе облысы",
                "enrolled": 4
              },
              "3": {
                "number": 28057,
                "first_stage": 24224,
                "second_stage": 556,

                "id": 6,
                "ru": "Алматинская область",
                "kz": "Алматы облысы",
                "enrolled": 22
              },
              "4": {
                "number": 5736,
                "first_stage": 5059,
                "second_stage": 173,
                "id": 7,
                "ru": "Атырауская область",
                "kz": "Атырау облысы",
                "enrolled": 17
              },
              "5": {
                "number": 4455,
                "first_stage": 4436,
                "second_stage": 294,
                "id": 8,
                "ru": "Западно-Казахстанская область",
                "kz": "Батыс Қазақстан облысы",
                "enrolled": 18
              },
              "6": {
                "number": 14275,
                "first_stage": 12949,
                "second_stage": 659,
                "id": 9,
                "ru": "Жамбылская область",
                "kz": "Жамбыл облысы",
                "enrolled": 29
              },
              "7": {
                "number": 3656,
                "first_stage": 3346,
                "second_stage": 109,
                "id": 10,
                "ru": "Карагандинская область",
                "kz": "Қарағанды облысы",
                "enrolled": 12
              },
              "8": {
                "number": 4726,
                "first_stage": 4599,
                "second_stage": 124,
                "id": 11,
                "ru": "Костанайская область",
                "kz": "Қостанай облысы",
                "enrolled": 9
              },
              "9": {
                "number": 11339,
                "first_stage": 10681,
                "second_stage": 874,
                "id": 12,
                "ru": "Кызылординская область",
                "kz": "Қызылорда облысы",
                "enrolled": 13
              },
              "10": {
                "number": 9175,
                "first_stage": 7770,
                "second_stage": 166,
                "id": 13,
                "ru": "Мангистауская область",
                "kz": "Маңғыстау облысы",
                "enrolled": 11
              },
              "11": {
                "number": 3181,
                "first_stage": 2806,
                "second_stage": 172,
                "id": 14,
                "ru": "Павлодарская область",
                "kz": "Павлодар облысы",
                "enrolled": 14
              },
              "12": {
                "number": 5824,
                "first_stage": 5369,
                "second_stage": 84,
                "id": 15,
                "ru": "Северо-Казахстанская область",
                "kz": "Солтүстік Қазақстан облысы",
                "enrolled": 8
              },
              "13": {
                "number": 33441,
                "first_stage": 30858,
                "second_stage": 967,
                "id": 16,
                "ru": "Туркестанская область",
                "kz": "Түркістан облысы",
                "enrolled": 20
              },
              "14": {
                "number": 10826,
                "first_stage": 9884,
                "second_stage": 290,
                "id": 17,
                "ru": "Восточно-Казахстанская область",
                "kz": "Шығыс Қазақстан облысы",
                "enrolled": 6
              }
            };
            this.loadingApi.regionsRating = false
          })
    }

    // this.$http.get(window.mynbala + `/api/dashboard/regions-rating?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
    //     .then((res) => {
    //       this.regionsRating = {
    //         "1": {
    //           "number": 6920,
    //           "first_stage": 6368,
    //           "second_stage": 129,
    //           "id": 4,
    //           "ru": "Акмолинская область",
    //           "kz": "Ақмола облысы",
    //           "enrolled": 12
    //         },
    //         "2": {
    //           "number": 5292,
    //           "first_stage": 4246,
    //           "second_stage": 117,
    //           "id": 5,
    //           "ru": "Актюбинская область",
    //           "kz": "Ақтөбе облысы",
    //           "enrolled": 4
    //         },
    //         "3": {
    //           "number": 28057,
    //           "first_stage": 24224,
    //           "second_stage": 556,
    //
    //           "id": 6,
    //           "ru": "Алматинская область",
    //           "kz": "Алматы облысы",
    //           "enrolled": 22
    //         },
    //         "4": {
    //           "number": 5736,
    //           "first_stage": 5059,
    //           "second_stage": 173,
    //           "id": 7,
    //           "ru": "Атырауская область",
    //           "kz": "Атырау облысы",
    //           "enrolled": 17
    //         },
    //         "5": {
    //           "number": 4455,
    //           "first_stage": 4436,
    //           "second_stage": 294,
    //           "id": 8,
    //           "ru": "Западно-Казахстанская область",
    //           "kz": "Батыс Қазақстан облысы",
    //           "enrolled": 18
    //         },
    //         "6": {
    //           "number": 14275,
    //           "first_stage": 12949,
    //           "second_stage": 659,
    //           "id": 9,
    //           "ru": "Жамбылская область",
    //           "kz": "Жамбыл облысы",
    //           "enrolled": 29
    //         },
    //         "7": {
    //           "number": 3656,
    //           "first_stage": 3346,
    //           "second_stage": 109,
    //           "id": 10,
    //           "ru": "Карагандинская область",
    //           "kz": "Қарағанды облысы",
    //           "enrolled": 12
    //         },
    //         "8": {
    //           "number": 4726,
    //           "first_stage": 4599,
    //           "second_stage": 124,
    //           "id": 11,
    //           "ru": "Костанайская область",
    //           "kz": "Қостанай облысы",
    //           "enrolled": 9
    //         },
    //         "9": {
    //           "number": 11339,
    //           "first_stage": 10681,
    //           "second_stage": 874,
    //           "id": 12,
    //           "ru": "Кызылординская область",
    //           "kz": "Қызылорда облысы",
    //           "enrolled": 13
    //         },
    //         "10": {
    //           "number": 9175,
    //           "first_stage": 7770,
    //           "second_stage": 166,
    //           "id": 13,
    //           "ru": "Мангистауская область",
    //           "kz": "Маңғыстау облысы",
    //           "enrolled": 11
    //         },
    //         "11": {
    //           "number": 3181,
    //           "first_stage": 2806,
    //           "second_stage": 172,
    //           "id": 14,
    //           "ru": "Павлодарская область",
    //           "kz": "Павлодар облысы",
    //           "enrolled": 14
    //         },
    //         "12": {
    //           "number": 5824,
    //           "first_stage": 5369,
    //           "second_stage": 84,
    //           "id": 15,
    //           "ru": "Северо-Казахстанская область",
    //           "kz": "Солтүстік Қазақстан облысы",
    //           "enrolled": 8
    //         },
    //         "13": {
    //           "number": 33441,
    //           "first_stage": 30858,
    //           "second_stage": 967,
    //           "id": 16,
    //           "ru": "Туркестанская область",
    //           "kz": "Түркістан облысы",
    //           "enrolled": 20
    //         },
    //         "14": {
    //           "number": 10826,
    //           "first_stage": 9884,
    //           "second_stage": 290,
    //           "id": 17,
    //           "ru": "Восточно-Казахстанская область",
    //           "kz": "Шығыс Қазақстан облысы",
    //           "enrolled": 6
    //         }
    //       };
    //       this.loadingApi.regionsRating = false
    //     })

    if (this.currentYear != 2021 && this.currentYear != 2022) {
      this.loadingApi.participation = true
      this.$http.get(window.mynbala + `/api/dashboard/participation-statistics?from=${this.currentYear}-01-01&to=${this.currentYear}-12-31`)
          .then((res) => {
            this.participation = res.body;
            var participationtotal = (this.participation[6].all.total + this.participation[5].all.total)
            this.series[0] = 100
            this.series[1] = Math.round((this.participation[6].approved.total / this.participation[6].all.total) * 100)
            this.series[2] = Math.round((this.participation[6].declined.total / this.participation[6].all.total) * 100)
            this.loadingApi.participation = false
          })
    } else {
      this.loadingApi.participation = true
      this.$http.get(window.mynbala + `/api/dashboard/participation-statistics?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.participation = res.body;
            var participationtotal = (this.participation[6].all.total + this.participation[5].all.total)
            this.series[0] = 100
            this.series[1] = 52
            this.series[2] = 48
            this.loadingApi.participation = false
          })
    }

    if (this.currentYear != 2021 && this.currentYear != 2022) {
      this.loadingApi.enrolled = true
      this.$http.get(window.mynbala + `/api/dashboard/enrolled?from=${this.currentYear}-01-01&to=${this.currentYear}-12-31`)
          .then((res) => {
            this.enrolled = res.body
            this.loadingApi.enrolled = false
          })
    } else {
      this.loadingApi.enrolled = true
      this.$http.get(window.mynbala + `/api/dashboard/enrolled?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.enrolled = res.body
            this.loadingApi.enrolled = false
          })
    }

    if (this.currentYear != 2022) {
      // this.loadingApi.yearStat = true
      this.$http.get(window.mynbala + `/api/dashboard/year-statistics?from=2021&to=${this.currentYear}`)
          .then((res) => {
            let y;
            let z;
            this.chartOptionsYear.xaxis.categories = res.body.enrolled.map(x => x.year_of_participation)
            var arr = res.body.enrolled.map(x => x.year_of_participation)

            this.yearStatistics = res.body

            for (var i = 0; i < res.body.total.length; i++) {
              z = res.body.total.map(x => x.number);
              for (y = 0; y < res.body.total.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearTotal.push(
                  {
                    name: res.body.total[i].year_of_participation,
                    data: z
                  })
            }

            this.seriesYearTotal[0].data = [(this.yearStatistics.total.filter( item => item.year_of_participation == 2021 ))[0].number]
            this.seriesYearTotal[1].data = [0, 146903]
            this.seriesYearTotal[2].data = [0, 0, (this.yearStatistics.total.filter( item => item.year_of_participation == 2023 ))[0].number]

            for (i = 0; i < res.body.first_stage.length; i++) {
              z = res.body.first_stage.map(x => x.number);
              for (y = 0; y < res.body.first_stage.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearFirstStage.push(
                  {
                    name: res.body.first_stage[i].year_of_participation,
                    data: z
                  })
            }
            this.seriesYearFirstStage[0].data = [(this.yearStatistics.first_stage.filter( item => item.year_of_participation == 2021 ))[0].number]
            this.seriesYearFirstStage[1].data = [0, 132485]
            this.seriesYearFirstStage[2].data = [0, 0, (this.yearStatistics.first_stage.filter( item => item.year_of_participation == 2023 ))[0].number]

            for (i = 0; i < res.body.second_stage.length; i++) {
              z = res.body.second_stage.map(x => x.number);
              for (y = 0; y < res.body.second_stage.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearSecondStage.push(
                  {
                    name: res.body.second_stage[i].year_of_participation,
                    data: z
                  })
            }
            this.seriesYearSecondStage[0].data = [(this.yearStatistics.second_stage.filter( item => item.year_of_participation == 2021 ))[0].number]
            this.seriesYearSecondStage[1].data = [0, 4714]
            this.seriesYearSecondStage[2].data = [0, 0, (this.yearStatistics.second_stage.filter( item => item.year_of_participation == 2023 ))[0].number]

            for (i = 0; i < res.body.enrolled.length; i++) {
              z = res.body.enrolled.map(x => x.number);
              for (y = 0; y < res.body.enrolled.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearEnroled.push(
                  {
                    name: res.body.enrolled[i].year_of_participation,
                    data: z
                  })
            }

            this.loadingApi.yearStat = false
          })
    } else {
      this.loadingApi.yearStat = true
      this.$http.get(window.mynbala + `/api/dashboard/year-statistics?from=2021&to=2022`)
          .then((res) => {
            let y;
            let z;
            this.chartOptionsYear.xaxis.categories = res.body.enrolled.map(x => x.year_of_participation)
            var arr = res.body.enrolled.map(x => x.year_of_participation)

            this.yearStatistics = res.body


            for (var i = 0; i < res.body.total.length; i++) {
              z = res.body.total.map(x => x.number);
              for (y = 0; y < res.body.total.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearTotal.push(
                  {
                    name: res.body.total[i].year_of_participation,
                    data: z
                  })
            }

            this.seriesYearTotal[0].data = [137223]
            this.seriesYearTotal[1].data = [0, 146903]
            for (i = 0; i < res.body.first_stage.length; i++) {
              z = res.body.first_stage.map(x => x.number);
              for (y = 0; y < res.body.first_stage.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearFirstStage.push(
                  {
                    name: res.body.first_stage[i].year_of_participation,
                    data: z
                  })
            }
            this.seriesYearFirstStage[0].data = [92876]
            this.seriesYearFirstStage[1].data = [0, 132485]

            for (i = 0; i < res.body.second_stage.length; i++) {
              z = res.body.second_stage.map(x => x.number);
              for (y = 0; y < res.body.second_stage.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearSecondStage.push(
                  {
                    name: res.body.second_stage[i].year_of_participation,
                    data: z
                  })
            }
            this.seriesYearSecondStage[0].data = [3862]
            this.seriesYearSecondStage[1].data = [0, 4714]
            for (i = 0; i < res.body.enrolled.length; i++) {
              z = res.body.enrolled.map(x => x.number);
              for (y = 0; y < res.body.enrolled.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearEnroled.push(
                  {
                    name: res.body.enrolled[i].year_of_participation,
                    data: z
                  })
            }
            this.loadingApi.yearStat = false

          })
    }
  },
  filters: {
    Numerize: function (value) {
      if (value == 0) return '0'
      if (!value) return ''
      if (value <= 999) return value
      return value.toLocaleString().replace(',', ' ')
    }
  },
  watch: {
    'loadingApi': {
      handler(newValue, oldValue) {
        this.loading = !Object.values(this.loadingApi).every(elem => elem == false)
      },
      deep: true
    }
  },

  methods: {
    getNumber(num) {
      let numEl = parseInt(num.match(/\d+/))
      return numEl
    },
    setYear() {
      if(this.currentYear==2021){
        this.$router.push('/myn-bala2021')
      } else {
        this.$router.go(0);
      }
      localStorage.setItem('myn_bala_current_year', this.currentYear)
    },
    regionsRatingRevertSchool(column) {
      let item;
      if (this.tableRevertSchool[column] == '') {
        item = 'desc'
        this.tableRevertSchool.number = ''
        this.tableRevertSchool.first_stage = ''
        this.tableRevertSchool.second_stage = ''
        this.tableRevertSchool.enrolled = ''
        this.tableRevertSchool[column] = 'desc'
      } else if (this.tableRevertSchool[column] == 'desc') {
        item = 'asc'
        this.tableRevertSchool.number = ''
        this.tableRevertSchool.first_stage = ''
        this.tableRevertSchool.second_stage = ''
        this.tableRevertSchool.enrolled = ''
        this.tableRevertSchool[column] = 'asc'
      } else if (this.tableRevertSchool[column] == 'asc') {
        item = ''
        this.tableRevertSchool.number = ''
        this.tableRevertSchool.first_stage = ''
        this.tableRevertSchool.second_stage = ''
        this.tableRevertSchool.enrolled = ''
        this.tableRevertSchool[column] = ''
      }
      if (item == '') {
        column = ''
      }
      this.$http.get(window.mynbala + '/api/dashboard/schools-rating?region=' + this.region_id_school + `&from=2022-01-01&to=2022-12-31&sort[field]=${column}&sort[direction]=${item}&page=${this.CurrentPage}`)
          .then((res) => {
            this.schools = res.body.data;
            this.meta = res.body.last_page
            this.filterLoading = false
          })
    },
    regionsRatingRevertSchool2023(column) {
      let item;
      if (this.tableRevertSchool[column] == '') {
        item = 'desc'
        this.tableRevertSchool.number = ''
        this.tableRevertSchool.first_stage = ''
        this.tableRevertSchool.second_stage = ''
        this.tableRevertSchool.enrolled = ''
        this.tableRevertSchool[column] = 'desc'
      } else if (this.tableRevertSchool[column] == 'desc') {
        item = 'asc'
        this.tableRevertSchool.number = ''
        this.tableRevertSchool.first_stage = ''
        this.tableRevertSchool.second_stage = ''
        this.tableRevertSchool.enrolled = ''
        this.tableRevertSchool[column] = 'asc'
      } else if (this.tableRevertSchool[column] == 'asc') {
        item = ''
        this.tableRevertSchool.number = ''
        this.tableRevertSchool.first_stage = ''
        this.tableRevertSchool.second_stage = ''
        this.tableRevertSchool.enrolled = ''
        this.tableRevertSchool[column] = ''
      }
      if (item == '') {
        column = ''
      }
      this.$http.get(window.mynbala + '/api/dashboard/schools-rating?region=' + this.region_id_school + `&from=${this.currentYear}-01-01&to=${this.currentYear}-12-31&sort[field]=${column}&sort[direction]=${item}&page=${this.CurrentPage}`)
          .then((res) => {
            this.schools = res.body.data;
            this.meta = res.body.last_page
            this.filterLoading = false
          })
    },
    regionsRatingRevert(column) {
      let item;
      if (this.tableRevert[column] == '') {
        item = 'desc'
        this.tableRevert.number = ''
        this.tableRevert.first_stage = ''
        this.tableRevert.second_stage = ''
        this.tableRevert.enrolled = ''
        this.tableRevert[column] = 'desc'
      } else if (this.tableRevert[column] == 'desc') {
        item = 'asc'
        this.tableRevert.number = ''
        this.tableRevert.first_stage = ''
        this.tableRevert.second_stage = ''
        this.tableRevert.enrolled = ''
        this.tableRevert[column] = 'asc'
      } else if (this.tableRevert[column] == 'asc') {
        item = ''
        this.tableRevert.number = ''
        this.tableRevert.first_stage = ''
        this.tableRevert.second_stage = ''
        this.tableRevert.enrolled = ''
        this.tableRevert[column] = ''
      }
      if (item == '') {
        column = ''
      }

      if (this.currentYear != 2022) {
        this.$http.get(window.mynbala + `/api/dashboard/regions-rating?from=${this.currentYear}-01-01&to=${this.currentYear}-12-31&sort[field]=${column}&sort[direction]=${item}`)
            .then((res) => {
              this.regionsRating = res.body;
              this.loadingApi.regionsRating = false
            })
      } else {
        this.$http.get(window.mynbala + `/api/dashboard/regions-rating?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31&sort[field]=${column}&sort[direction]=${item}`)
            .then((res) => {
              this.regionsRating = Object.values({
                "_region_4_": {
                  "number": 6787,
                  "first_stage": 6773,
                  "second_stage": 263,
                  "enrolled": 13,
                  "id": 4,
                  "ru": "\u0410\u043a\u043c\u043e\u043b\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u0410\u049b\u043c\u043e\u043b\u0430 \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_5_": {
                  "number": 6993,
                  "first_stage": 6992,
                  "second_stage": 244,
                  "enrolled": 4,
                  "id": 5,
                  "ru": "\u0410\u043a\u0442\u044e\u0431\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u0410\u049b\u0442\u04e9\u0431\u0435 \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_6_": {
                  "number": 31630,
                  "first_stage": 31601,
                  "second_stage": 1051,
                  "enrolled": 27,
                  "id": 6,
                  "ru": "\u0410\u043b\u043c\u0430\u0442\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u0410\u043b\u043c\u0430\u0442\u044b \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_7_": {
                  "number": 3457,
                  "first_stage": 3447,
                  "second_stage": 139,
                  "enrolled": 18,
                  "id": 7,
                  "ru": "\u0410\u0442\u044b\u0440\u0430\u0443\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u0410\u0442\u044b\u0440\u0430\u0443 \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_8_": {
                  "number": 5092,
                  "first_stage": 5077,
                  "second_stage": 213,
                  "enrolled": 17,
                  "id": 8,
                  "ru": "\u0417\u0430\u043f\u0430\u0434\u043d\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u0411\u0430\u0442\u044b\u0441 \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_9_": {
                  "number": 22463,
                  "first_stage": 22455,
                  "second_stage": 743,
                  "enrolled": 21,
                  "id": 9,
                  "ru": "\u0416\u0430\u043c\u0431\u044b\u043b\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u0416\u0430\u043c\u0431\u044b\u043b \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_10_": {
                  "number": 4403,
                  "first_stage": 4389,
                  "second_stage": 162,
                  "enrolled": 11,
                  "id": 10,
                  "ru": "\u041a\u0430\u0440\u0430\u0433\u0430\u043d\u0434\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u049a\u0430\u0440\u0430\u0493\u0430\u043d\u0434\u044b \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_11_": {
                  "number": 3242,
                  "first_stage": 3224,
                  "second_stage": 136,
                  "enrolled": 6,
                  "id": 11,
                  "ru": "\u041a\u043e\u0441\u0442\u0430\u043d\u0430\u0439\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u049a\u043e\u0441\u0442\u0430\u043d\u0430\u0439 \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_12_": {
                  "number": 9124,
                  "first_stage": 9111,
                  "second_stage": 339,
                  "enrolled": 22,
                  "id": 12,
                  "ru": "\u041a\u044b\u0437\u044b\u043b\u043e\u0440\u0434\u0438\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u049a\u044b\u0437\u044b\u043b\u043e\u0440\u0434\u0430 \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_13_": {
                  "number": 6278,
                  "first_stage": 6268,
                  "second_stage": 222,
                  "enrolled": 9,
                  "id": 13,
                  "ru": "\u041c\u0430\u043d\u0433\u0438\u0441\u0442\u0430\u0443\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u041c\u0430\u04a3\u0493\u044b\u0441\u0442\u0430\u0443 \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_14_": {
                  "number": 3223,
                  "first_stage": 3215,
                  "second_stage": 139,
                  "enrolled": 11,
                  "id": 14,
                  "ru": "\u041f\u0430\u0432\u043b\u043e\u0434\u0430\u0440\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u041f\u0430\u0432\u043b\u043e\u0434\u0430\u0440 \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_15_": {
                  "number": 4000,
                  "first_stage": 3994,
                  "second_stage": 168,
                  "enrolled": 14,
                  "id": 15,
                  "ru": "\u0421\u0435\u0432\u0435\u0440\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u0421\u043e\u043b\u0442\u04af\u0441\u0442\u0456\u043a \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_16_": {
                  "number": 23011,
                  "first_stage": 23010,
                  "second_stage": 712,
                  "enrolled": 42,
                  "id": 16,
                  "ru": "\u0422\u0443\u0440\u043a\u0435\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u0422\u04af\u0440\u043a\u0456\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b"
                },
                "_region_17_": {
                  "number": 13490,
                  "first_stage": 13476,
                  "second_stage": 484,
                  "enrolled": 16,
                  "id": 17,
                  "ru": "\u0412\u043e\u0441\u0442\u043e\u0447\u043d\u043e-\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u043e\u0431\u043b\u0430\u0441\u0442\u044c",
                  "kz": "\u0428\u044b\u0493\u044b\u0441 \u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u043e\u0431\u043b\u044b\u0441\u044b"
                }
              });
              this.loadingApi.regionsRating = false
            })
      }

    },
    filterMynbalaNUM() {
      if (this.region_id_mynbalaNum == 0) {
        this.region_id_mynbalaNum = ''
      }
      this.chartPatr = false
      this.$http.get(window.mynbala + `/api/dashboard/participation-statistics?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31&region=${this.region_id_mynbalaNum}`)
          .then((res) => {
            this.participation = res.body;
            var participationtotal = (this.participation[6].all.total + this.participation[5].all.total)
            this.series[0] = Math.round(((this.participation[6].all.total + this.participation[5].all.total) / participationtotal * 100) * 100) / 100
            this.series[1] = Math.round(((this.participation[6].approved.total + this.participation[5].approved.total) / participationtotal * 100) * 100) / 100
            this.series[2] = Math.round(((this.participation[6].declined.total + this.participation[5].declined.total) / participationtotal * 100) * 100) / 100
            this.chartPatr = true
          })
    },
    filterGender() {
      if (this.region_id_gender == 0) {
        this.region_id_gender = ''
      }
      this.$http.get(window.mynbala + `/api/dashboard/gender-statistics?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31&region=${this.region_id_gender}`)
          .then((res) => {
            this.genderStats = res.body;
          })
    },
    statsFilter(page = 1) {
      if (this.filterLoading == false) {
        if (page <= this.meta && page >= 1) {
          this.filterLoading = true
          this.CurrentPage = page
          this.$http.get(window.mynbala + `/api/dashboard/schools-rating?region=${this.schoolRatingRegionId}&page=${page}&from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
              .then((res) => {
                this.schools = res.body.data
                this.meta = res.body.last_page
                this.filterLoading = false
              })
        }
      }
    },
    statsFilter2023(page = 1) {
      if (this.filterLoading == false) {
        if (page <= this.meta && page >= 1) {
          this.filterLoading = true
          this.CurrentPage = page
          this.$http.get(window.mynbala + `/api/dashboard/schools-rating?region=${this.schoolRatingRegionId}&page=${page}&from=${this.currentYear}-01-01&to=${this.currentYear}-12-31`)
              .then((res) => {
                this.schools = res.body.data
                this.meta = res.body.last_page
                this.filterLoading = false
              })
        }
      }
    },
    updateInfo() {
      this.qabiletDateTo = this.qabiletDateFrom
      var max = new Date().getFullYear()
      this.yearFrom = this.years[0].name
      this.yearTo = this.years[this.years.length - 1].name
      setInterval(this.clock, 1000);
      this.$http.get(window.mynbala + '/api/references/regions')
          .then((res) => {
            this.regions = res.body.data;
            this.loadingApi.regions = false
          })
      this.loadingApi.regionPlaces = true
      this.$http.get(window.mynbala + `/api/dashboard/region-places?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.regionPlaces = res.body;
            this.loadingApi.regionPlaces = false
          })
      this.loadingApi.schoolPlaces = true

      if (this.currentYear != 2022 && this.currentYear != 2021) {
        console.log('school-places 2023')
        this.$http.get(window.mynbala + `/api/dashboard/school-places?from=${this.currentYear}-01-01&to=${this.currentYear}-12-31`)
            .then((res) => {
              this.sshod = res.body
              this.sshod__chartSeries = []
              this.sshod__chartOptions.labels = []
              for (var x = 0; x < res.body.schools_by_regions.length; x++) {
                this.sshod__chartSeries.push(res.body.schools_by_regions[x].number)
                this.sshod__chartOptions.labels.push(res.body.schools_by_regions[x].name)
              }
              this.loadingApi.schoolPlaces = false
            })
      } else {
        console.log('school-places 2022')
        this.$http.get(window.mynbala + `/api/dashboard/school-places?from=2022-01-01&to=2022-12-31`)
            .then((res) => {
              this.sshod = res.body
              this.sshod__chartSeries = []
              this.sshod__chartOptions.labels = []
              for (var x = 0; x < res.body.schools_by_regions.length; x++) {
                this.sshod__chartSeries.push(res.body.schools_by_regions[x].number)
                this.sshod__chartOptions.labels.push(res.body.schools_by_regions[x].name)
              }
              this.loadingApi.schoolPlaces = false
            })
      }

      this.loadingApi.gender = true

      this.$http.get(window.mynbala + `/api/dashboard/gender-statistics?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.genderStats = res.body;
            this.loadingApi.gender = false
          })
      this.loadingApi.schools = true
      this.$http.get(window.mynbala + `/api/dashboard/schools-rating?region=16&from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.meta = res.body.last_page
            this.CurrentPage = res.body.current_page;
            this.schools = res.body.data;
            this.filterLoading = false
            this.loadingApi.schools = false
          })
      this.loadingApi.regionsRating = true
      this.$http.get(window.mynbala + `/api/dashboard/regions-rating?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.regionsRating = res.body;
            this.loadingApi.regionsRating = false
          })
      this.loadingApi.participation = true
      this.$http.get(window.mynbala + `/api/dashboard/participation-statistics?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.participation = res.body;
            var participationtotal = (this.participation[6].all.total + this.participation[5].all.total)
            this.series[0] = Math.round(((this.participation[6].all.total + this.participation[5].all.total) / participationtotal * 100) * 100) / 100
            this.series[1] = Math.round(((this.participation[6].approved.total + this.participation[5].approved.total) / participationtotal * 100) * 100) / 100
            this.series[2] = Math.round(((this.participation[6].declined.total + this.participation[5].declined.total) / participationtotal * 100) * 100) / 100
            this.loadingApi.participation = false
          })
      this.loadingApi.enrolled = true
      this.$http.get(window.mynbala + `/api/dashboard/enrolled?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.enrolled = res.body
            this.loadingApi.enrolled = false
          })


    },
    changeLang() {
      this.loading = true
      localStorage.setItem('locale', this.locale);
      this.$i18n.locale = this.locale;
      this.$router.go()
    },
    filterChartsBlock() {
      this.loadChart = true
      if (this.yearFrom == null) {
        this.yearFrom = ''
      }
      if (this.yearTo == null) {
        this.yearTo = ''
      }
      this.seriesYearTotal = []
      this.seriesYearFirstStage = []
      this.seriesYearSecondStage = []
      this.seriesYearEnroled = []

      this.$http.get(window.mynbala + `/api/dashboard/year-statistics?from=${this.yearFrom}&to=${this.yearTo}`)
          .then((res) => {
            let y;
            let z;
            this.chartOptionsYear.xaxis.categories = res.body.enrolled.map(x => x.year_of_participation)
            var arr = res.body.enrolled.map(x => x.year_of_participation)

            this.yearStatistics = res.body


            for (var i = 0; i < res.body.total.length; i++) {
              z = res.body.total.map(x => x.number);
              for (y = 0; y < res.body.total.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearTotal.push(
                  {
                    name: res.body.total[i].year_of_participation,
                    data: z
                  })
            }


            for (i = 0; i < res.body.first_stage.length; i++) {
              z = res.body.first_stage.map(x => x.number);
              for (y = 0; y < res.body.first_stage.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearFirstStage.push(
                  {
                    name: res.body.first_stage[i].year_of_participation,
                    data: z
                  })
            }


            for (i = 0; i < res.body.second_stage.length; i++) {
              z = res.body.second_stage.map(x => x.number);
              for (y = 0; y < res.body.second_stage.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearSecondStage.push(
                  {
                    name: res.body.second_stage[i].year_of_participation,
                    data: z
                  })
            }

            for (i = 0; i < res.body.enrolled.length; i++) {
              z = res.body.enrolled.map(x => x.number);
              for (y = 0; y < res.body.enrolled.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearEnroled.push(
                  {
                    name: res.body.enrolled[i].year_of_participation,
                    data: z
                  })
            }
            this.loadChart = false
            this.loadingApi.yearStat = false

          })
    },


    clock() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();
      this.date = dd + '.' + mm + '.' + yyyy;
      var date = new Date(),
          hours = (date.getHours() < 10) ? '0' + date.getHours() : date.getHours(),
          minutes = (date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes(),
          seconds = (date.getSeconds() < 10) ? '0' + date.getSeconds() : date.getSeconds();
      this.time = hours + ':' + minutes + ':' + seconds;
    },
    filterChart(id) {
      this.$http.get(window.mynbala + '/api/dashboard/school-places?region=' + id)
          .then((res) => {
            this.currentRegionChart = id
            this.sshod = res.body
            this.sshod__chartSeries = []
            this.sshod__chartOptions.labels = []
            for (var x = 0; x < res.body.schools_by_regions.length; x++) {
              this.sshod__chartSeries.push(res.body.schools_by_regions[x].number)
              this.sshod__chartOptions.labels.push(res.body.schools_by_regions[x].name)
            }

          })
    },
    filterRegionSchool(id) {
      this.schoolRatingRegionId = id
      this.filterLoading = true
      this.$http.get(window.mynbala + '/api/dashboard/schools-rating?region=' + id + `&from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.CurrentPage = 1
            this.schools = res.body.data;
            this.meta = res.body.last_page
            this.filterLoading = false
          })
    },
    filterStage(year) {
      this.$http.get(window.mynbala + '/api/dashboard/first-stage?year=' + year)
          .then((res) => {
            this.stage = res.body
            this.stage2021_1 = res.body[1]
            this.stage2021_1.data = [res.body[1].data[0], res.body[1].data[0]]
            this.stage2021_2 = res.body[2]
            this.stage2021_2.data = [res.body[2].data[0], res.body[2].data[0]]
          })
    }
  },
  changeLang() {
    localStorage.setItem('locale', this.locale);
    this.$i18n.locale = this.locale;
    this.$router.go()
  },

}
</script>
<style lang="css" scoped>
.rating-table__header-helper {
  min-width: 261px !important;
  max-width: 261px !important;
}

.rating-table__header-helper-2 {
  min-width: 150px !important;
  max-width: 150px !important;
}

.rotate180 {
  top: 29% !important;
  transform: rotate(0deg) !important;
}

.rating-table-revert {
  background-position: bottom;
  height: 10px;
  width: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  border: unset;
  background-color: unset;
  transform: rotate(180deg);
  background-image: url('/images/polygon-myb.svg');
  position: absolute;
  right: 30px;
  top: 30px;
}

.mb-card-first__info {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: auto;
}

.tasks__pagination .page-link {
  color: white;
  background: #74B2F0;
}

.tasks__pagination .page-link.active {
  background: #eff7ff;
  color: #2091f9;
}

.loader {
  background: conic-gradient(#74B2F0, transparent) !important;
}

@media (max-width: 991px) {
  .mb-card-third__desc {
    justify-content: center;
    text-align: center;
  }

  .mb-card-third__row {
    justify-content: center;
  }

  .mb-card-second__row {
    justify-content: space-between;
  }

  .flex-wrap-mob {
    flex-wrap: wrap;
  }

  .mb-card-second {
    margin-left: auto;
    margin-right: auto;
  }

  .mb-card-third {
    margin-left: auto;
    margin-right: auto;
  }

  .mb-card-first {
    width: 100%
  }
}

@media (max-width: 767px) {
  .mb-card-first__info-1 {
    padding: 26px;
  }

  .mb-card-first__info-2 {
    padding: 26px;
  }

  .mb-card-first__info-3 {
    padding: 26px;
  }

  .mb-card-first__info-4 {
    padding: 26px;
  }

  .mb-card-first__chart {
    margin: auto;
  }

  .mb-card-first__content {
    flex-direction: column;
  }

  .comparison-card {
    width: 100%;
  }

  .olympiad-comparison__cards {
    flex-direction: column;
  }

  .sshod__col-cards {
    margin-right: 0;
  }

  .sshod__cols {
    flex-direction: column;
  }

  .sshod__card {
    max-width: unset;
    width: 100%;
    margin-bottom: 1rem !important;
  }

  .sshod__chart {
    max-width: unset;
    width: 100%;
  }
}

</style>
